// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/main_logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-title {\n  padding-left: 80px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: contain; }\n\n.site-item {\n  cursor: pointer;\n  position: relative;\n  width: 200px;\n  height: 130px; }\n  .site-item:hover {\n    transform: scale(1.05);\n    background-color: white;\n    transition: transform 200ms ease-in-out; }\n\n.site-item-bg {\n  position: absolute;\n  bottom: 0;\n  right: 3%;\n  font-size: 30px;\n  font-weight: bold;\n  color: #c5c5c5;\n  opacity: .2; }\n\n.site-logo {\n  width: 158px; }\n\n.header-actions {\n  display: flex;\n  justify-content: flex-end; }\n", ""]);
// Exports
module.exports = exports;
